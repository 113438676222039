<template>
  <base-create-employee :employee="employee">
    <template v-slot:header>
      <label class="fs-22 fw-med">
        {{ employee ? 'Edit Employee' : 'New Employee' }}
      </label>
    </template>
  </base-create-employee>
</template>

<script>
// Components
import BaseCreateEmployee from '../../components/base-employee-create.vue'

export default {
  name: 'CreateEditEmployee',

  components: {
    BaseCreateEmployee
  },

  props: {
    // If employee exists, base-create-employee will go to "editing mode"
    employee: {
      type: Object,
      required: false,
      default: null,
    }
  }
}
</script>