<template>
  <div class="CreateEmployee width-100">
    <div class="CreateEmployee__header row">
      <slot name="header" />
    </div>

    <hr class="Divider">

    <div class="CreateEmployee__employee column">
      <div class="row row--wrap">
        <base-input
          v-model.trim="firstName.value"
          class="CreateEmployee__input mr-8 mb-25"
          :instructions="instructions.SHORTENED_NAME_INSTRUCTION"
          :label="true"
          :label-for="'first-name'"
          :valid="firstName.valid"
        >
          First Name
        </base-input>

        <base-input
          v-model.trim="lastName.value"
          class="CreateEmployee__input mr-8 mb-25"
          :instructions="instructions.SHORTENED_NAME_INSTRUCTION"
          :label="true"
          :label-for="'last-name'"
          :valid="lastName.valid"
        >
          Last Name
        </base-input>

        <base-input
          v-model.trim="email.value"
          class="CreateEmployee__input mr-9 mb-25"
          :disabled="!!employee"
          :instructions="
            email.unique
              ? instructions.EMAIL_INSTRUCTION
              : instructions.EMAIL_UNIQUE_INSTRUCTION
          "
          :label="true"
          :label-for="'email'"
          :valid="email.valid && email.unique"
        >
          Email
        </base-input>

        <base-input
          v-model.trim="extension.value"
          class="CreateEmployee__input mr-9 mb-25"
          id="Testing__EmployeeExtension"
          :instructions="instructions.EXTENSION_INSTRUCTION"
          :label="true"
          :label-for="'extension'"
          :valid="extension.valid"
        >
          Extension
        </base-input>

        <div class="mb-25 column column--width-auto">
          <label
            class="BaseInput__label uppercase"
            for="role"
          >
            {{ role.valid ? 'Role' : 'Select a role' }}
          </label>
          <v-select
            v-model="role.value"
            aria-label="Select input to choose role option"
            id="role"
            :class="['CreateEmployee__select mr-9', {
              'CreateEmployee__select--invalid': !role.valid
            }]"
            :clearable="false"
            :options="role.options"
          />
        </div>

        <div
          v-if="employee"
          class="column column--width-auto mb-25"
        >
          <label
            class="BaseInput__label uppercase"
            for="role"
          >
            Status
          </label>
          <v-select
            v-model="status.value"
            aria-label="Select input to choose status option"
            id="status"
            :class="['CreateEmployee__select mr-9', {
              'CreateEmployee__select--invalid': !status.valid
            }]"
            :clearable="false"
            :options="status.options"
          />
        </div>
      </div>
    </div>

    <hr class="Divider">

    <div class="CreateEmployee__save-wrap column column--align-center">
      <base-button
        v-if="!employee"
        @click="submitEmployee"
        class="CreateEmployee__save-button bg-blue fc-white"
        id="Testing__EmployeeSubmitButton"
        :disabled="buttonDisabled || !isValid"
      >
        Save Employee
      </base-button>
      <base-button
        v-else
        @click="updateEmployee"
        class="CreateEmployee__save-button bg-blue fc-white"
        id="Testing__EmployeeUpdateButton"
        :disabled="buttonDisabled || !isValid"
      >
        Update Employee
      </base-button>
    </div>
  </div>
</template>

<script>
// Helpers
import { Employee } from '../utils/api'
// Components
import BaseButton from './base-button.vue'
import BaseInput from './base-input.vue'
// Mixins
import {
  ValidationMixin
} from '../utils/validation-mixin'

export default {
  name: 'CreateEmployee',

  components: {
    BaseButton,
    BaseInput
  },

  props: {
    employee: {
      type: Object,
      required: false,
      default: null
    },
  },

  mixins: [ValidationMixin],

  created () {
    this.user = JSON.parse(localStorage.getItem('user'))
    // only a master can create a master employee
    if (this.user.employee && this.user.employee.is_master) this.role.options.push('Master')

    if (this.employee) {
      this.hydrate()
    }
  },

  data () {
    /*
     * Required fields:
     * email, firstName, lastName, role
     */
    return {
      buttonDisabled: false,
      email: {
        unique: true,
        valid: false,
        value: ''
      },
      extension: {
        valid: true,
        value: ''
      },
      firstName: {
        valid: false,
        value: ''
      },
      lastName: {
        valid: false,
        value: ''
      },
      role: {
        options: [
          'Account Manager',
          'Underwriter',
          'Collections Specialist',
          'Processor',
          'Salesperson',
        ],
        valid: false,
        value: ''
      },
      status: {
        options: [
          'active',
          'inactive',
        ],
        valid: true,
        value: ''
      },
      user: null
    }
  },

  computed: {
    isValid () {
      let valid = true

      if (!this.firstName.value || !this.isValidShortenedName(this.firstName.value)) {
        this.$set(this.firstName, 'valid', false)
        valid = false
      } else {
        this.$set(this.firstName, 'valid', true)
      }

      if (!this.lastName.value || !this.isValidShortenedName(this.lastName.value)) {
        this.$set(this.lastName, 'valid', false)
        valid = false
      } else {
        this.$set(this.lastName, 'valid', true)
      }

      if (!this.email.value || !this.isValidEmail(this.email.value)) {
        this.$set(this.email, 'valid', false)
        valid = false
      } else {
        this.$set(this.email, 'valid', true)
      }

      if (this.extension.value && !this.isValidExtension(this.extension.value)) {
        this.$set(this.extension, 'valid', false)
        valid = false
      } else {
        this.$set(this.extension, 'valid', true)
      }

      if (!this.role.value || !this.isValidRole(this.role.value)) {
        this.$set(this.role, 'valid', false)
        valid = false
      } else {
        this.$set(this.role, 'valid', true)
      }

      return valid
    }
  },

  methods: {
    hydrate () {
      this.$set(this.firstName, 'value', this.employee.first_name)
      this.$set(this.lastName, 'value', this.employee.last_name)
      this.$set(this.email, 'value', this.employee.user.email)
      this.$set(this.extension, 'value', this.employee.extension)
      this.$set(this.role, 'value', this.employee.role)
      this.$set(this.status, 'value', this.employee.status)
    },

    async submitEmployee() {
      this.progressStart()
      this.buttonDisabled = true

      if (!this.isValid || !(await this.unique())) {
        this.buttonDisabled = false
        return
      }

      try {
        const newEmployee = (await Employee.create({
          email: this.email.value.toLowerCase(),
          extension: this.extension.value,
          first_name: this.firstName.value,
          is_master: this.role.value === 'Master',
          last_name: this.lastName.value,
          role: this.role.value,
          user: { email: this.email.value }
        })).data

        // insert it into queue so it will show up immediately
        this.$store.commit('INSERT_USER_CREATE_QUEUE', {
          type: 'employee',
          ...newEmployee,
        })

        this.requestSuccess({ message: 'Employee Successfully Created!' })
        this.$router.push({
          name: 'user-management',
          params: {
            newEmployee: true
          }
        })
      }
      catch (error) {
        this.captureSentryEvent(
          'Create Employee "Submit Employee"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.buttonDisabled = false
        this.requestFailure({ message: 'There was an issue creating a new employee' })
      }
    },

    async unique () {
      try {
        // EMPLOYEE
        // email must be unique
        if (!(await Employee.uniqueField({ useremail: this.email.value })).data) {
          this.$set(this.email, 'unique', false)
          return false
        }
        this.$set(this.email, 'unique', true)
        return true
      }
      catch (error) {
        this.captureSentryEvent(
          'Create Employee "Unique"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue checking the uniqueness of the email' })
        return false
      }
    },

    async updateEmployee () {
      if (!this.isValid) return

      this.buttonDisabled = true
      this.progressStart()
      try {
        await Employee.update({
          email: this.email.value.toLowerCase(),
          extension: this.extension.value,
          first_name: this.firstName.value,
          id: this.employee.id,
          is_master: this.role.value === 'Master',
          last_name: this.lastName.value,
          role: this.role.value,
          status: this.status.value,
          user: { email: this.email.value }
        })

        this.requestSuccess({ message: 'Employee Successfully Updated!' })
        this.$router.push({
          name: 'user-management',
          params: {
            updatedEmployee: true
          }
        })
      }
      catch (error) {
        this.captureSentryEvent(
          'Edit Employee "Update Employee"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.buttonDisabled = false
        this.requestFailure({ message: 'There was an issue updating the employee' })
      }
    }
  }
}
</script>

<style lang="sass">
.CreateEmployee
  background-color: $white
  border: $border
  border-radius: $border-radius
  box-shadow: $container-shadow
  margin-right: rem(50px)
  margin-top: rem(52px)
  padding: rem(44px) rem(47px) rem(21px) rem(29px)

  &__employee
    margin-bottom: rem(26px)
    margin-top: rem(41px)

  &__header
    margin-bottom: rem(41px)

  &__input
    width: rem(240px)

  &__save-button
    width: rem(326px)

  &__save-wrap
    margin-bottom: rem(100px)
    margin-top: rem(50px)

  &__select
    width: rem(240px)

    &--invalid
      border: $border
      border-color: $danger
      border-radius: $border-radius

</style>