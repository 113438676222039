var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "CreateEmployee width-100" }, [
    _c(
      "div",
      { staticClass: "CreateEmployee__header row" },
      [_vm._t("header")],
      2
    ),
    _c("hr", { staticClass: "Divider" }),
    _c("div", { staticClass: "CreateEmployee__employee column" }, [
      _c(
        "div",
        { staticClass: "row row--wrap" },
        [
          _c(
            "base-input",
            {
              staticClass: "CreateEmployee__input mr-8 mb-25",
              attrs: {
                instructions: _vm.instructions.SHORTENED_NAME_INSTRUCTION,
                label: true,
                "label-for": "first-name",
                valid: _vm.firstName.valid
              },
              model: {
                value: _vm.firstName.value,
                callback: function($$v) {
                  _vm.$set(
                    _vm.firstName,
                    "value",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "firstName.value"
              }
            },
            [_vm._v("\n        First Name\n      ")]
          ),
          _c(
            "base-input",
            {
              staticClass: "CreateEmployee__input mr-8 mb-25",
              attrs: {
                instructions: _vm.instructions.SHORTENED_NAME_INSTRUCTION,
                label: true,
                "label-for": "last-name",
                valid: _vm.lastName.valid
              },
              model: {
                value: _vm.lastName.value,
                callback: function($$v) {
                  _vm.$set(
                    _vm.lastName,
                    "value",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "lastName.value"
              }
            },
            [_vm._v("\n        Last Name\n      ")]
          ),
          _c(
            "base-input",
            {
              staticClass: "CreateEmployee__input mr-9 mb-25",
              attrs: {
                disabled: !!_vm.employee,
                instructions: _vm.email.unique
                  ? _vm.instructions.EMAIL_INSTRUCTION
                  : _vm.instructions.EMAIL_UNIQUE_INSTRUCTION,
                label: true,
                "label-for": "email",
                valid: _vm.email.valid && _vm.email.unique
              },
              model: {
                value: _vm.email.value,
                callback: function($$v) {
                  _vm.$set(
                    _vm.email,
                    "value",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "email.value"
              }
            },
            [_vm._v("\n        Email\n      ")]
          ),
          _c(
            "base-input",
            {
              staticClass: "CreateEmployee__input mr-9 mb-25",
              attrs: {
                id: "Testing__EmployeeExtension",
                instructions: _vm.instructions.EXTENSION_INSTRUCTION,
                label: true,
                "label-for": "extension",
                valid: _vm.extension.valid
              },
              model: {
                value: _vm.extension.value,
                callback: function($$v) {
                  _vm.$set(
                    _vm.extension,
                    "value",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "extension.value"
              }
            },
            [_vm._v("\n        Extension\n      ")]
          ),
          _c(
            "div",
            { staticClass: "mb-25 column column--width-auto" },
            [
              _c(
                "label",
                {
                  staticClass: "BaseInput__label uppercase",
                  attrs: { for: "role" }
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.role.valid ? "Role" : "Select a role") +
                      "\n        "
                  )
                ]
              ),
              _c("v-select", {
                class: [
                  "CreateEmployee__select mr-9",
                  {
                    "CreateEmployee__select--invalid": !_vm.role.valid
                  }
                ],
                attrs: {
                  "aria-label": "Select input to choose role option",
                  id: "role",
                  clearable: false,
                  options: _vm.role.options
                },
                model: {
                  value: _vm.role.value,
                  callback: function($$v) {
                    _vm.$set(_vm.role, "value", $$v)
                  },
                  expression: "role.value"
                }
              })
            ],
            1
          ),
          _vm.employee
            ? _c(
                "div",
                { staticClass: "column column--width-auto mb-25" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "BaseInput__label uppercase",
                      attrs: { for: "role" }
                    },
                    [_vm._v("\n          Status\n        ")]
                  ),
                  _c("v-select", {
                    class: [
                      "CreateEmployee__select mr-9",
                      {
                        "CreateEmployee__select--invalid": !_vm.status.valid
                      }
                    ],
                    attrs: {
                      "aria-label": "Select input to choose status option",
                      id: "status",
                      clearable: false,
                      options: _vm.status.options
                    },
                    model: {
                      value: _vm.status.value,
                      callback: function($$v) {
                        _vm.$set(_vm.status, "value", $$v)
                      },
                      expression: "status.value"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ]),
    _c("hr", { staticClass: "Divider" }),
    _c(
      "div",
      { staticClass: "CreateEmployee__save-wrap column column--align-center" },
      [
        !_vm.employee
          ? _c(
              "base-button",
              {
                staticClass: "CreateEmployee__save-button bg-blue fc-white",
                attrs: {
                  id: "Testing__EmployeeSubmitButton",
                  disabled: _vm.buttonDisabled || !_vm.isValid
                },
                on: { click: _vm.submitEmployee }
              },
              [_vm._v("\n      Save Employee\n    ")]
            )
          : _c(
              "base-button",
              {
                staticClass: "CreateEmployee__save-button bg-blue fc-white",
                attrs: {
                  id: "Testing__EmployeeUpdateButton",
                  disabled: _vm.buttonDisabled || !_vm.isValid
                },
                on: { click: _vm.updateEmployee }
              },
              [_vm._v("\n      Update Employee\n    ")]
            )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }