var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-create-employee", {
    attrs: { employee: _vm.employee },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function() {
          return [
            _c("label", { staticClass: "fs-22 fw-med" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.employee ? "Edit Employee" : "New Employee") +
                  "\n    "
              )
            ])
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }